.wrapper {
    height: 100vh;
    background: linear-gradient(90deg, hsla(213, 100%, 46%, 1) 0%, hsla(213, 100%, 46%, 1) 50%, hsla(29, 92%, 95%, 1) 50%, hsla(29, 92%, 95%, 1) 100%);
    padding: 40px;
    position: relative;
    overflow: hidden;
    &:after {
      content: '404';
      position: absolute;
      display: block;
      color: hsla(0, 0%, 75%, 1);
      font-size: 50vh;
      opacity: 0.2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }
}

.logo {
  width: 148px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  color: white;
  line-height: 54px;
  margin-top: 12vh;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.leftImg {
  position: absolute;
  bottom: -14vh;
  left: -10vh;
  height: 70vh!important;
  width: auto!important;
  pointer-events: none;
    @media (max-height: 660px) {
      display: none;
    }
}

.rightImg {
  position: absolute;
  bottom: -8vh;
  right: -10vh;
  height: 45vh !important;
  width: auto!important;
  transform: scaleX(-1);
  pointer-events: none;
}

.description {
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 20px;
  color: white;
  line-height: 34px;
  max-width: 400px;
  @media (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.btn {
  width: 250px;
  height: 45px;
  border: 1px solid hsla(213, 100%, 46%, 1);
  background: white;
  color: hsla(213, 100%, 46%, 1);
  border-radius: 7px;
}
