.wrapper {
  height: 100vh;
  background: rgb(240, 240, 240);
  padding: 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styled404 {
  font-size: 120px;
  color: hsla(0, 0%, 75%, 1);
  font-weight: bold;
  line-height: 120px;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: hsla(0, 0%, 28%, 1);
}

.button {
  min-width: 100%;
  height: 45px;
  background: hsla(213, 100%, 46%, 1) ;
  color: white;
  border-radius: 7px;
}

.description {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: hsla(0, 0%, 28%, 1);
  line-height: 20px;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3);
  width: auto !important;
}